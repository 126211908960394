import React, {useState } from "react";

const ProductPopup = ({ orderProducts }) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [expertRecommended, setExpertRecommended] = useState(false);

  const openPopup = () => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };
  console.log(orderProducts);

  return (
    <div>
      {/* Button to view the products list */}
      <button
        onClick={openPopup}
        style={{
          backgroundColor: "#EFC081",
          color: "white",
          padding: "10px 20px",
          fontSize: "1rem",
          borderRadius: "5px",
          cursor: "pointer",
          border: "none",
          fontWeight: "bold",
        }}
      >
        View Products
      </button>

      {/* Popup Modal */}
      {isPopupOpen && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1000,
          }}
        >
          <div
            style={{
              width: "80%",
              maxHeight: "80vh",
              backgroundColor: "white",
              borderRadius: "10px",
              overflowY: "auto",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
              padding: "20px",
            }}
          >
            <div style={{ textAlign: "right" }}>
              <button
                onClick={closePopup}
                style={{
                  backgroundColor: "#ff4d4d",
                  color: "white",
                  border: "none",
                  padding: "10px 20px",
                  fontSize: "1rem",
                  cursor: "pointer",
                  borderRadius: "5px",
                  fontWeight: "bold",
                  transition: "background-color 0.3s",
                }}
                onMouseOver={(e) =>
                  (e.target.style.backgroundColor = "#cc0000")
                }
                onMouseOut={(e) => (e.target.style.backgroundColor = "#ff4d4d")}
              >
                Close
              </button>
            </div>

            <h2
              style={{
                fontWeight: "bold",
                fontSize: "24px",
                textAlign: "center",
                marginBottom: "20px",
                color: "black",
              }}
            >
              Order Products
            </h2>
            {orderProducts?.length > 0 ? (
              <ul style={{ padding: 0, margin: 0, listStyle: "none" }}>
                {orderProducts.map((product, index) => (
                  <li
                    key={index}
                    style={{
                      marginBottom: "20px",
                      padding: "15px",
                      border: "1px solid #ddd",
                      borderRadius: "8px",
                      background: index % 2 === 0 ? "#f9f9f9" : "#f1f1f1", // Alternating background colors
                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                      position: "relative"
                    }}
                  >
                    <h3
                      style={{
                        marginBottom: "10px",
                        color: "black",
                        fontWeight: "bold",
                      }}
                    >
                      {index + 1}.{product?.product?.title || "N/A"}
                    </h3>
                   
                    <hr
                      style={{ margin: "10px 0", border: "1px solid #ddd" }}
                    />{" "}
                    {/* Line separator */}
                    {/* Display product details in lines */}
                    <div style={{ color: "black" }}>
                      <strong style={{ fontWeight: "bold", color: "black" }}>
                        Order ID:
                      </strong>{" "}
                      {product?.product?.orderId || "N/A"}
                    </div>
                    <div style={{ color: "black" }}>
                      <strong style={{ fontWeight: "bold", color: "black" }}>
                        Date:
                      </strong>{" "}
                      {product?.date || "N/A"}
                    </div>
                    <div style={{ color: "black" }}>
                      <strong style={{ fontWeight: "bold", color: "black" }}>
                        Price:
                      </strong>{" "}
                      {product?.price || "N/A"}
                    </div>
                    <div style={{ color: "black" }}>
                      <strong style={{ fontWeight: "bold", color: "black" }}>
                        Discount:
                      </strong>{" "}
                      {product?.discount || "N/A"}
                    </div>
                    <div style={{ color: "black" }}>
                      <strong style={{ fontWeight: "bold", color: "black" }}>
                        Quantity:
                      </strong>{" "}
                      {product?.quantity || "N/A"}
                    </div>
                    <div style={{ color: "black" }}>
                      <strong style={{ fontWeight: "bold", color: "black" }}>
                        Is Review:
                      </strong>{" "}
                      {product?.isReview ? "Yes" : "No"}
                    </div>
                    {/* Confirmation details */}
                    <div style={{ marginTop: "10px", color: "black" }}>
                      <strong style={{ fontWeight: "bold", color: "black" }}>
                        Confirmation:
                      </strong>
                      <div style={{ marginLeft: "15px" }}>
                        <div>
                          <strong
                            style={{ fontWeight: "bold", color: "black" }}
                          >
                            ID:
                          </strong>{" "}
                          {product?.confirmation?.id || "N/A"}
                        </div>
                        <div>
                          <strong
                            style={{ fontWeight: "bold", color: "black" }}
                          >
                            Status:
                          </strong>{" "}
                          {product?.confirmation?.status
                            ? "Confirmed"
                            : "Not Confirmed"}
                        </div>
                        <div>
                          <strong
                            style={{ fontWeight: "bold", color: "black" }}
                          >
                            Date:
                          </strong>{" "}
                          {product?.confirmation?.date || "N/A"}
                        </div>
                      </div>
                    </div>
                    {/* Shipment details */}
                    <div style={{ marginTop: "10px", color: "black" }}>
                      <strong style={{ fontWeight: "bold", color: "black" }}>
                        Shipment:
                      </strong>
                      <div style={{ marginLeft: "15px" }}>
                        <div>
                          <strong
                            style={{ fontWeight: "bold", color: "black" }}
                          >
                            ID:
                          </strong>{" "}
                          {product?.shipment?.id || "N/A"}
                        </div>
                        <div>
                          <strong
                            style={{ fontWeight: "bold", color: "black" }}
                          >
                            Status:
                          </strong>{" "}
                          {product?.shipment?.status
                            ? "Shipped"
                            : "Not Shipped"}
                        </div>
                        <div>
                          <strong
                            style={{ fontWeight: "bold", color: "black" }}
                          >
                            Date:
                          </strong>{" "}
                          {product?.shipment?.date || "N/A"}
                        </div>
                      </div>
                    </div>
                    {/* Delieverd details */}
                    <div style={{ marginTop: "10px", color: "black" }}>
                      <strong style={{ fontWeight: "bold", color: "black" }}>
                        Delivered:
                      </strong>
                      <div style={{ marginLeft: "15px" }}>
                        <div>
                          <strong
                            style={{ fontWeight: "bold", color: "black" }}
                          >
                            ID:
                          </strong>{" "}
                          {product?.delivered?.id || "N/A"}
                        </div>
                        <div>
                          <strong
                            style={{ fontWeight: "bold", color: "black" }}
                          >
                            Status:
                          </strong>{" "}
                          {product?.delivered?.status
                            ? "Delivered"
                            : "Not Delivered"}
                        </div>
                        <div>
                          <strong
                            style={{ fontWeight: "bold", color: "black" }}
                          >
                            Date:
                          </strong>{" "}
                          {product?.delivered?.date || "N/A"}
                        </div>
                      </div>
                    </div>
                    {/* Returned details */}
                    <div style={{ marginTop: "10px", color: "black" }}>
                      <strong style={{ fontWeight: "bold", color: "black" }}>
                        Returned:
                      </strong>
                      <div style={{ marginLeft: "15px" }}>
                        <div>
                          <strong
                            style={{ fontWeight: "bold", color: "black" }}
                          >
                            ID:
                          </strong>{" "}
                          {product?.returned?.id || "N/A"}
                        </div>
                        <div>
                          <strong
                            style={{ fontWeight: "bold", color: "black" }}
                          >
                            Status:
                          </strong>{" "}
                          {product?.returned?.status
                            ? "Returned"
                            : "Not Returned"}
                        </div>
                        <div>
                          <strong
                            style={{ fontWeight: "bold", color: "black" }}
                          >
                            Date:
                          </strong>{" "}
                          {product?.returned?.date || "N/A"}
                        </div>
                      </div>
                    </div>
                    <div style={{ marginTop: "10px", color: "black" }}>
                      <strong style={{ fontWeight: "bold", color: "black" }}>
                        Product ID:
                      </strong>{" "}
                      {product?.product?.id || "N/A"}
                    </div>
                    <div style={{ marginTop: "10px", color: "black" }}>
                      <strong style={{ fontWeight: "bold", color: "black" }}>
                        Product Title:
                      </strong>{" "}
                      {product?.product?.title || "N/A"}
                    </div>
                    <div style={{ marginTop: "10px", color: "black" }}>
                      <strong style={{ fontWeight: "bold", color: "black" }}>
                        Product Active:
                      </strong>{" "}
                      {product?.product?.isActive ? "Yes" : "No" || "N/A"}
                    </div>
                    {/* <div
                      style={{
                        marginTop: "10px",
                        color: "black",
                        display: "flex",
                        alignItems: "center",
                      }}
                    > */}
                      {/* <strong style={{ fontWeight: "bold", color: "black" }}>
                        Product Image:
                      </strong>
                      <img
                        src={
                          product?.product?.image?.src ||
                          "default-image-url.jpg"
                        }
                        alt={product?.product?.image?.alt || "Product Image"}
                        style={{
                          width: "100px",
                          height: "100px",
                          marginLeft: "15px",
                        }}
                      />
                    </div>
                    <div style={{ marginTop: "10px", color: "black" }}>
                      <strong style={{ fontWeight: "bold", color: "black" }}>
                        Product Image ID:
                      </strong>{" "}
                      {product?.product?.image?.imageId || "N/A"}
                    </div>
                    <div style={{ marginTop: "10px", color: "black" }}>
                      <strong style={{ fontWeight: "bold", color: "black" }}>
                        Product Image Position:
                      </strong>{" "}
                      {product?.product?.image?.position || "N/A"}
                    </div>
                    <div style={{ marginTop: "10px", color: "black" }}>
                      <strong style={{ fontWeight: "bold", color: "black" }}>
                        Product Image Created At:
                      </strong>{" "}
                      {product?.product?.image?.createdAt || "N/A"}
                    </div>
                    <div style={{ marginTop: "10px", color: "black" }}>
                      <strong style={{ fontWeight: "bold", color: "black" }}>
                        Product Image Updated At:
                      </strong>{" "}
                      {product?.product?.image?.updatedAt || "N/A"}
                    </div> */}
                    {/* Description Fields */}
                    <div style={{ marginTop: "10px", color: "black" }}>
                      <strong style={{ fontWeight: "bold", color: "black" }}>
                        Product Description ID:
                      </strong>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: product?.product?.description?.id || "N/A",
                        }}
                      />
                    </div>
                    <div style={{ marginTop: "10px", color: "black" }}>
  <strong
    style={{
      fontWeight: "bold",
      color: "black",
      whiteSpace: "normal",
      wordWrap: "break-word",
    }}
  >
    Product Description:
  </strong>
  <div
    style={{
      wordWrap: "break-word", // Ensure long words break correctly
      overflowWrap: "break-word", // Support for more modern browsers
      wordBreak: "break-word", // Fallback for certain cases
      whiteSpace: "pre-wrap", // Maintain spacing and wrap lines
      overflow: "hidden", // Prevent overflow
    }}
    dangerouslySetInnerHTML={{
      __html: product?.product?.description?.description || "N/A",
    }}
  />
</div>

                    <div style={{ marginTop: "10px", color: "black" }}>
                      <strong style={{ fontWeight: "bold", color: "black" }}>
                        Use Before:
                      </strong>{" "}
                      {product?.product?.description?.useBefore || "N/A"}
                    </div>
                    <div style={{ marginTop: "10px", color: "black" }}>
                      <strong style={{ fontWeight: "bold", color: "black" }}>
                        Features and Benefits:
                      </strong>
                      {product?.product?.description?.featuresAndBenefits
                        .length > 0
                        ? product?.product?.description?.featuresAndBenefits.join(
                            ", "
                          )
                        : "N/A"}
                    </div>
                    <div style={{ marginTop: "10px", color: "black" }}>
                      <strong style={{ fontWeight: "bold", color: "black" }}>
                        How to Use:
                      </strong>{" "}
                      {product?.product?.description?.howToUse || "N/A"}
                    </div>
                    <div style={{ marginTop: "10px", color: "black" }}>
                      <strong style={{ fontWeight: "bold", color: "black" }}>
                        Ingredients:
                      </strong>
                      {product?.product?.description?.ingredients.length > 0
                        ? product?.product?.description?.ingredients.join(", ")
                        : "N/A"}
                    </div>
                    <div style={{ marginTop: "10px", color: "black" }}>
                      <strong style={{ fontWeight: "bold", color: "black" }}>
                        Purpose:
                      </strong>
                      {product?.product?.description?.purpose.length > 0
                        ? product?.product?.description?.purpose.join(", ")
                        : "N/A"}
                    </div>
                    <div style={{ marginTop: "10px", color: "black" }}>
                      <strong style={{ fontWeight: "bold", color: "black" }}>
                        Country of Origin:
                      </strong>{" "}
                      {product?.product?.description?.countryOfOrigin || "N/A"}
                    </div>
                    <div style={{ marginTop: "10px", color: "black" }}>
                      <strong style={{ fontWeight: "bold", color: "black" }}>
                        Manufacturer:
                      </strong>{" "}
                      {product?.product?.description?.manufacturer || "N/A"}
                    </div>
                    <div style={{ marginTop: "10px", color: "black" }}>
                      <strong style={{ fontWeight: "bold", color: "black" }}>
                        Common Generic Name:
                      </strong>{" "}
                      {product?.product?.description?.commonGenericName ||
                        "N/A"}
                    </div>
                    {/* Display Product Images */}
                    {product?.product?.images?.length > 0 ? (
                      product?.product?.images.map((image, index) => (
                        <div
                          key={image.id}
                          style={{ marginTop: "10px", color: "black" }}
                        >
                          <strong
                            style={{ fontWeight: "bold", color: "black" }}
                          >
                            Image {index + 1}:
                          </strong>
                          <div>
                            <img
                              src={image?.src || "default-image-url.jpg"}
                              alt={image?.alt || "Product Image"}
                              style={{ width: "100px", height: "100px" }}
                            />
                          </div>
                          <div>
                            <strong
                              style={{ fontWeight: "bold", color: "black" }}
                            >
                              Image ID:
                            </strong>{" "}
                            {image?.id || "N/A"}
                          </div>
                          <div>
                            <strong
                              style={{ fontWeight: "bold", color: "black" }}
                            >
                              Position:
                            </strong>{" "}
                            {image?.position || "N/A"}
                          </div>
                          <div>
                            <strong
                              style={{ fontWeight: "bold", color: "black" }}
                            >
                              Created At:
                            </strong>{" "}
                            {image?.createdAt || "N/A"}
                          </div>
                          <div>
                            <strong
                              style={{ fontWeight: "bold", color: "black" }}
                            >
                              Updated At:
                            </strong>{" "}
                            {image?.updatedAt || "N/A"}
                          </div>
                          <div>
                            <strong
                              style={{ fontWeight: "bold", color: "black" }}
                            >
                              Alt Text:
                            </strong>{" "}
                            {image?.alt || "N/A"}
                          </div>
                          <div>
                            <strong
                              style={{ fontWeight: "bold", color: "black" }}
                            >
                              Width:
                            </strong>{" "}
                            {image?.width || "N/A"}
                          </div>
                          <div>
                            <strong
                              style={{ fontWeight: "bold", color: "black" }}
                            >
                              Height:
                            </strong>{" "}
                            {image?.height || "N/A"}
                          </div>
                          <hr
                            style={{ margin: "10px 0", borderColor: "black" }}
                          />
                        </div>
                      ))
                    ) : (
                      <div style={{ marginTop: "10px", color: "black" }}>
                        No images available.
                      </div>
                    )}
                   
                    {/* Product Reviews */}
                    <div style={{ marginTop: "10px", color: "black" }}>
                      <strong style={{ fontWeight: "bold", color: "black" }}>
                        Product Reviews:
                      </strong>
                      {product?.product?.productReviews?.length > 0 ? (
                        <ul>
                          {product?.product?.productReviews.map(
                            (review, index) => (
                              <li
                                key={index}
                                style={{
                                  listStyleType: "none",
                                  margin: "5px 0",
                                }}
                              >
                                <div>
                                  <strong
                                    style={{
                                      fontWeight: "bold",
                                      color: "black",
                                    }}
                                  >
                                    Rating:
                                  </strong>{" "}
                                  {review?.rating || "N/A"}
                                </div>
                                <div>
                                  <strong
                                    style={{
                                      fontWeight: "bold",
                                      color: "black",
                                    }}
                                  >
                                    Comment:
                                  </strong>{" "}
                                  {review?.comment || "No comment available."}
                                </div>
                                <div>
                                  <strong
                                    style={{
                                      fontWeight: "bold",
                                      color: "black",
                                    }}
                                  >
                                    Delivery Experience:
                                  </strong>{" "}
                                  {review?.deliveryExperience || "No Delivery Experience available."}
                                </div>
                                <div>
                                  <strong
                                    style={{
                                      fontWeight: "bold",
                                      color: "black",
                                    }}
                                  >
                                    Created At:
                                  </strong>{" "}
                                  {review?.createdAt || "N/A"}
                                </div>
                              </li>
                            )
                          )}
                        </ul>
                      ) : (
                        <span>No reviews available.</span>
                      )}
                    </div>
                  
                   
                    {/* Recent Searches
                    <div style={{ marginTop: "10px", color: "black" }}>
                      <strong style={{ fontWeight: "bold", color: "black" }}>
                        Recent Searches:
                      </strong>
                      {product?.product?.recentSearches?.length > 0 ? (
                        product.product.recentSearches.map((search, index) => (
                          <div key={search.id} style={{ marginTop: "10px" }}>
                            <strong
                              style={{ fontWeight: "bold", color: "black" }}
                            >
                              Search {index + 1}:
                            </strong>
                            <div>
                              <strong
                                style={{ fontWeight: "bold", color: "black" }}
                              >
                                Customer ID:
                              </strong>{" "}
                              {search.customerId || "N/A"}
                            </div>
                            <div>
                              <strong
                                style={{ fontWeight: "bold", color: "black" }}
                              >
                                Product ID:
                              </strong>{" "}
                              {search.productId || "N/A"}
                            </div>
                            <div>
                              <strong
                                style={{ fontWeight: "bold", color: "black" }}
                              >
                                Created At:
                              </strong>{" "}
                              {search.createdAt || "N/A"}
                            </div>
                            <div>
                              <strong
                                style={{ fontWeight: "bold", color: "black" }}
                              >
                                Updated At:
                              </strong>{" "}
                              {search.updatedAt || "N/A"}
                            </div>
                            <hr
                              style={{ margin: "10px 0", borderColor: "black" }}
                            />
                          </div>
                        ))
                      ) : (
                        <span>No recent searches available.</span>
                      )}
                    </div> */}
                    {/* Collection */}
                    {/* <div style={{ marginTop: "10px", color: "black" }}>
                      <strong style={{ fontWeight: "bold", color: "black" }}>
                        Collection:
                      </strong>
                      {product?.product?.collection?.length > 0 ? (
                        product.product.collection.map((collection, index) => (
                          <div
                            key={collection.id}
                            style={{ marginTop: "10px" }}
                          >
                            <strong
                              style={{ fontWeight: "bold", color: "black" }}
                            >
                              Collection {index + 1}:
                            </strong>
                            <div>
                              <strong
                                style={{ fontWeight: "bold", color: "black" }}
                              >
                                {collection?.handle === "ncds-pick"
                                  ? "Expert Recommendation ID:"
                                  : "ID:"}
                              </strong>{" "}
                              {collection?.id || "N/A"}
                            </div>
                            {collection?.handle?.toLowerCase() === "ncds-pick" && (
  <div
    style={{
      position: "absolute",
      top: "5px",
      right: "5px",
      padding: "5px 10px",
      backgroundColor: "#4CAF50",
      color: "white",
      borderRadius: "5px",
      fontWeight: "bold",
      fontSize: "0.9rem",
      marginTop: "5px",
    }}
  >
    Expert Recommended
  </div>
)}

                            
                            <div>
                              <strong
                                style={{ fontWeight: "bold", color: "black" }}
                              >
                                Title:
                              </strong>{" "}
                              {collection.title || "N/A"}
                            </div>
                            <div>
                              <strong
                                style={{ fontWeight: "bold", color: "black" }}
                              >
                                Handle:
                              </strong>{" "}
                              {collection.handle || "N/A"}
                            </div>
                            <div>
                              <strong
                                style={{ fontWeight: "bold", color: "black" }}
                              >
                                Is Active:
                              </strong>{" "}
                              {collection.isActive ? "Yes" : "No"}
                            </div>
                            <div>
                              <strong
                                style={{ fontWeight: "bold", color: "black" }}
                              >
                                Updated At Date:
                              </strong>{" "}
                              {collection.updatedAtDate || "N/A"}
                            </div>

                            <div>
                              <strong
                                style={{ fontWeight: "bold", color: "black" }}
                              >
                                Published At Date:
                              </strong>{" "}
                              {collection.publishedAtDate || "N/A"}
                            </div>
                            <div>
                              <strong
                                style={{ fontWeight: "bold", color: "black" }}
                              >
                                Sort Order:
                              </strong>{" "}
                              {collection.sortOrder || "N/A"}
                            </div>
                            <div>
                              <strong
                                style={{ fontWeight: "bold", color: "black" }}
                              >
                                Template Suffix:
                              </strong>{" "}
                              {collection.templateSuffix || "N/A"}
                            </div>
                            <div>
                              <strong
                                style={{ fontWeight: "bold", color: "black" }}
                              >
                                Published Scope:
                              </strong>{" "}
                              {collection.publishedScope || "N/A"}
                            </div>
                            <div>
                              <strong
                                style={{ fontWeight: "bold", color: "black" }}
                              >
                                Admin Graphql ID:
                              </strong>{" "}
                              {collection.adminGraphqlId || "N/A"}
                            </div>

                            <hr
                              style={{ margin: "10px 0", borderColor: "black" }}
                            />
                          </div>
                        ))
                      ) : (
                        <span>No collection available.</span>
                      )}
                    </div> */}
          {product?.product?.isExpertRecommended && <div
    style={{
      position: "absolute",
      top: "5px",
      right: "5px",
      padding: "5px 10px",
      backgroundColor: "#4CAF50",
      color: "white",
      borderRadius: "5px",
      fontWeight: "bold",
      fontSize: "0.9rem",
      marginTop: "5px",
    }}
  >
    Expert Recommended
  </div>}          
                    <div style={{ marginTop: "10px", color: "black" }}>
                      <strong style={{ fontWeight: "bold", color: "black" }}>
                        Wishlists Status:{product?.product?.wishlists?.length > 0 ? product?.product?.wishlists?.length: "No Wishlist"}
                      </strong>
                      </div>
                    {/* Wishlists */}
                    <div style={{ marginTop: "10px", color: "black" }}>
                      <strong style={{ fontWeight: "bold", color: "black" }}>
                        Wishlists:
                      </strong>
                      {product?.product?.wishlists?.length > 0 ? (
                        product.product.wishlists.map((wishlist, index) => (
                          <div key={wishlist.id} style={{ marginTop: "10px" }}>
                            <strong
                              style={{ fontWeight: "bold", color: "black" }}
                            >
                              Wishlist {index + 1}:
                            </strong>
                            <div>
                              <strong
                                style={{ fontWeight: "bold", color: "black" }}
                              >
                                Customer ID:
                              </strong>{" "}
                              {wishlist.customerId || "N/A"}
                            </div>
                            <div>
                              <strong
                                style={{ fontWeight: "bold", color: "black" }}
                              >
                                Created At:
                              </strong>{" "}
                              {wishlist.createdAt || "N/A"}
                            </div>
                            <div>
                              <strong
                                style={{ fontWeight: "bold", color: "black" }}
                              >
                                Updated At:
                              </strong>{" "}
                              {wishlist.updatedAt || "N/A"}
                            </div>

                            {/* Products */}
                            <div style={{ marginTop: "10px" }}>
                              <strong
                                style={{ fontWeight: "bold", color: "black" }}
                              >
                                Products:
                              </strong>
                              {wishlist.products?.length > 0 ? (
                                wishlist.products.map((product) => (
                                  <div
                                    key={product.id}
                                    style={{ marginLeft: "20px" }}
                                  >
                                    <div>
                                      <strong
                                        style={{
                                          fontWeight: "bold",
                                          color: "black",
                                        }}
                                      >
                                        Product ID:
                                      </strong>{" "}
                                      {product.id || "N/A"}
                                    </div>
                                    <div>
                                      <strong
                                        style={{
                                          fontWeight: "bold",
                                          color: "black",
                                        }}
                                      >
                                        Title:
                                      </strong>{" "}
                                      {product.title || "N/A"}
                                    </div>
                                  </div>
                                ))
                              ) : (
                                <span>No products available.</span>
                              )}
                            </div>

                            {/* Services */}
                            <div style={{ marginTop: "10px" }}>
                              <strong
                                style={{ fontWeight: "bold", color: "black" }}
                              >
                                Services:
                              </strong>
                              {wishlist.services?.length > 0 ? (
                                wishlist.services.map((service) => (
                                  <div
                                    key={service.id}
                                    style={{ marginLeft: "20px" }}
                                  >
                                    <div>
                                      <strong
                                        style={{
                                          fontWeight: "bold",
                                          color: "black",
                                        }}
                                      >
                                        Service ID:
                                      </strong>{" "}
                                      {service.id || "N/A"}
                                    </div>
                                    <div>
                                      <strong
                                        style={{
                                          fontWeight: "bold",
                                          color: "black",
                                        }}
                                      >
                                        Name:
                                      </strong>{" "}
                                      {service.name || "N/A"}
                                    </div>
                                  </div>
                                ))
                              ) : (
                                <span>No services available.</span>
                              )}
                            </div>
                            <hr
                              style={{ margin: "10px 0", borderColor: "black" }}
                            />
                          </div>
                        ))
                      ) : (
                        <span>No wishlists available.</span>
                      )}
                    </div>
                    {/* Metafields Section (Description, Ingredients, etc.) */}
                    <div style={{ marginTop: "10px", color: "black" }}>
                      <strong style={{ fontWeight: "bold", color: "black" }}>
                        Meta Fields:
                      </strong>
                    </div>
                    <div style={{ marginTop: "10px", color: "black" }}>
                      <strong style={{ fontWeight: "bold", color: "black" }}>
                        Product Description:
                      </strong>
                      <div
                        style={{ whiteSpace: "normal", wordWrap: "break-word" }}
                      >
                        {product?.product?.metafields?.description || "N/A"}
                      </div>
                    </div>
                    <div style={{ marginTop: "10px", color: "black" }}>
                      <strong style={{ fontWeight: "bold", color: "black" }}>
                        Features and Benefits:
                      </strong>
                      <div
                        style={{ whiteSpace: "normal", wordWrap: "break-word" }}
                      >
                        {product?.product?.metafields?.featuresAndBenefits ||
                          "N/A"}
                      </div>
                    </div>
                    <div style={{ marginTop: "10px", color: "black" }}>
                      <strong style={{ fontWeight: "bold", color: "black" }}>
                        How to Use:
                      </strong>
                      <div
                        style={{ whiteSpace: "normal", wordWrap: "break-word" }}
                      >
                        {product?.product?.metafields?.howToUse || "N/A"}
                      </div>
                    </div>
                    <div style={{ marginTop: "10px", color: "black" }}>
                      <strong style={{ fontWeight: "bold", color: "black" }}>
                        Ingredients:
                      </strong>
                      <div
                        style={{ whiteSpace: "normal", wordWrap: "break-word" }}
                      >
                        {product?.product?.metafields?.ingredients || "N/A"}
                      </div>
                    </div>
                    <div style={{ marginTop: "10px", color: "black" }}>
                      <strong style={{ fontWeight: "bold", color: "black" }}>
                        Information:
                      </strong>
                      <div
                        style={{ whiteSpace: "normal", wordWrap: "break-word" }}
                      >
                        {product?.product?.metafields?.information || "N/A"}
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            ) : (
              <p style={{ color: "black" }}>No products available</p>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductPopup;
