import React, { useCallback, useState, useEffect } from "react";
import { IoClose } from "react-icons/io5";
import { toast } from "react-toastify";
import {
  createTipAndTrick,
  updateTipAndTrick,
  uploadSingleDocument,
} from "../../apis";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import actions from "../../actions";

const AddEdit = ({
  fetch,
  data,
  logoutRequest,
  startLoadingRequest,
  stopLoadingRequest,
  type,
}) => {
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    docId: data?.document?.id || "",
    title: data?.title || "",
    caption: data?.caption || "",
    image: data?.document?.url || "",
    file: "",
  });

  const [errors, setErrors] = useState({
    docId: "",
    title: "",
    caption: "",
  });

  const openHandler = useCallback(() => {
    if(open){
      setFormData({
        docId: data?.document?.id || "",
        title: data?.title || "",
        caption: data?.caption || "",
        image: data?.document?.url || "",
        file: "",
      });
      setErrors({
        docId: "",
        title: "",
        caption: "",
      });
    }
    setOpen(!open);
  }, [open]);

  const changeHandler = useCallback(
    (e) => {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    },
    [formData]
  );

  const fileUploadHandler = useCallback(async () => {
    if (typeof formData?.file === "object") {
      const form = new FormData();
      form.append("file", formData.file);
      const apiResponse = await uploadSingleDocument(form);
      return apiResponse?.data?.data?.documentId;
    } else {
      return formData?.docId || "";
    }
  }, [formData]);

  const submitHandler = useCallback(async () => {
    try {
      startLoadingRequest();
      const docId = await fileUploadHandler();
      let cleanData = {
        title: formData?.title?.trim(),
        caption: formData?.caption?.trim(),
        docId: docId || formData?.docId?.trim(),
      };
      let tempErrors = {};

      if (!cleanData.docId) {
        tempErrors["docId"] = "Image is required";
      }
      if (!cleanData.caption) {
        tempErrors["caption"] = "Caption is required";
      }
      if (!cleanData.title) {
        tempErrors["title"] = "Title is required";
      }
      if (Object.keys(tempErrors)?.length > 0) {
        setErrors(tempErrors);
        stopLoadingRequest();
        return;
      } else {
        setErrors({});
      }
      if (data?.id) {
      const response =  await updateTipAndTrick(data?.id, cleanData);
        toast(response?.data?.message);

      } else {
      const response = await createTipAndTrick(cleanData);
        toast(response?.data?.message);
      }
      setOpen(false);
      setFormData({
        docId: data?.document?.id || "",
        title: data?.title || "",
        caption: data?.caption || "",
        image: data?.document?.url || "",
        file: "",
      });
      setErrors({
        docId: "",
        title: "",
        caption: "",
        image: "",
        file: "",
      });
      fetch();
    } catch (error) {
      stopLoadingRequest();
      toast.error(error?.response?.data?.message);
      if (error?.response?.status === 401) {
        logoutRequest();
      }
    }
  }, [formData, data]);

  useEffect(() => {
    if (data?.id) {
      setFormData({
        docId: data?.document?.id || "",
        title: data?.title || "",
        caption: data?.caption || "",
        image: data?.document?.url || "",
        file: "",
      });
    } else {
      setFormData({
        docId: "",
        title: "",
        caption: "",
        image: "",
        file: "",
      });
    }
  }, [data?.id]);

  // const fileHandler = useCallback(
  //   (event) => {
  //     if (event?.target?.files[0]) {
  //       setFormData({
  //         ...formData,
  //         file: event.target.files[0],
  //         image: URL.createObjectURL(event.target.files[0]),
  //       });
  //     }
  //   },
  //   [formData]
  // );

  const fileHandler = useCallback(
    (event) => {
      if (event?.target?.files[0]) {
        const file = event.target.files[0];
        const img = new Image();
        const objectUrl = URL.createObjectURL(file);
  
        img.onload = () => {
          const width = img.width;
          const height = img.height;
          const aspectRatio = width / height;
  
          const tolerance = 0.05; // Allow up to 5% deviation
  
          // Check if the aspect ratio is close to 1:1
          if (Math.abs(aspectRatio - 1) <= tolerance) {
            // Aspect ratio valid, set the image and file data
            setFormData({
              ...formData,
              file: file,
              image: objectUrl,
            });
          } else {
            // Invalid aspect ratio
            toast.error("Image aspect ratio must be approximately 1:1");
            URL.revokeObjectURL(objectUrl); // Clean up the object URL
          }
        };
  
        img.onerror = () => {
          toast.error("Failed to load image");
          URL.revokeObjectURL(objectUrl); // Clean up the object URL
        };
  
        img.src = objectUrl;
      }
    },
    [formData]
  );
  

  return (
    <div className="modal-container">
      <div className="add-modal">
        <button
          className={data?.id ? "secondary" : "add"}
          onClick={openHandler}
        >
          {data?.id ? "Edit" : "Add"}
        </button>
      </div>
      <div className={open ? "modal active" : "modal"}>
        <div className="wrapper">
          <div className="header">
            <h2>{data?.id ? "Edit Tip and Trick" : "Add Tip and Trick"}</h2>
            <button type="button" className="close" onClick={openHandler}>
              <IoClose size={18} />
            </button>
          </div>
          <div className="content">
            <div className="form-section">
            <div
                className="input-wrapper-image"
                style={{
                  border: !formData.image ? "2px solid #ccc" : "none",
                  width: "100%",
                  height: "300px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  position: "relative", // Ensure proper alignment of child elements
                }}
              >
                {formData.image ? (
                  <div className="image">
                    <img
                      src={formData.image}
                      alt="Uploaded Preview"
                      onClick={() => document.getElementById(type).click()} // Trigger file input
                      style={{
                        cursor: "pointer",
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }} // Ensure the image fits within the defined size
                    />
                  </div>
                ) : (
                  <label
                    htmlFor={type}
                    className="upload-button"
                    style={{
                      cursor: "pointer",
                      color: "#555",
                      fontSize: "14px",
                      textAlign: "center",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column", // Optional for multiple lines of text
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    Upload Image{" "}
                    {formData.type?.value === "Smart mirror(Home)"
                      ? "(Aspect ratio - 16:9)"
                      : "(Aspect ratio - 1:1)"}
                  </label>
                )}
                <input
                  onChange={fileHandler}
                  accept="image/*"
                  id={type}
                  type="file"
                  style={{ display: "none" }} // Hide the input field
                  key={formData.image || Date.now()}
                />
                  {errors?.docId && <p style={{position: "absolute", bottom: "-20px"}} className="error-text">{errors?.docId}</p>}

              </div>
              <div className="input-wrapper">
                <label>Title</label>
                <input
                  name="title"
                  value={formData.title}
                  onChange={changeHandler}
                />
                {errors.title && <p>{errors.title}</p>}
              </div>
              <div className="input-wrapper">
                <label>Caption</label>
                <input
                  name="caption"
                  value={formData.caption}
                  onChange={changeHandler}
                />
                {errors.caption && <p>{errors.caption}</p>}
              </div>
            </div>
          </div>
          <div className="footer">
            <button type="button" onClick={submitHandler} className="primary">
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logoutRequest: actions.auth.logout,
      startLoadingRequest: actions.loader.startLoading,
      stopLoadingRequest: actions.loader.stopLoading,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(AddEdit);
