import React, { useState } from "react";

const ReasonModal = ({ onClose, userToDeactivate, onSubmit, clickOn }) => {
  const [reason, setReason] = useState("");

  const handleSubmit = () => {
    if (clickOn === 0 && !reason.trim()) {
      alert("Please enter a reason to deactivate the user.");
      return;
    }
    if (reason.trim() || clickOn === 1) {
      onSubmit(reason, clickOn);
      setReason("");
      onClose();
    } else {
      alert("Please enter a reason.");
    }
  };

  // Format ISO string to readable date-time
  const formatTimestamp = (isoString) => {
    const date = new Date(isoString);
    return date.toLocaleString();
  };

  return (
    <div
      style={{
        position: "fixed",
        inset: "0",
        backgroundColor: "rgba(128, 128, 128, 0.5)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          backgroundColor: "white",
          padding: "24px",
          borderRadius: "8px",
          width: "400px",
          maxHeight: "90vh",
          overflowY: "auto",
        }}
      >
        <h2
          style={{
            fontSize: "18px",
            fontWeight: "600",
            marginBottom: "16px",
          }}
        >
          {clickOn === 0 ? "Deactivate User" : "Activate User"}
        </h2>
        <p style={{ marginBottom: "16px" }}>
          Are you sure you want to {clickOn === 0 ? "deactivate" : "activate"}{" "}
          {userToDeactivate?.firstName} {userToDeactivate?.lastName}?
        </p>

        {/* History of actions */}
        {userToDeactivate?.statusHistory?.length > 0 && (
          <div style={{ marginBottom: "16px" }}>
            <h3
              style={{
                fontSize: "16px",
                fontWeight: "500",
                marginBottom: "8px",
              }}
            >
              Activation/Deactivation History:
            </h3>
            <ul
              style={{
                listStyle: "none",
                padding: "0",
                margin: "0",
                maxHeight: "150px",
                overflowY: "auto",
              }}
            >
              {userToDeactivate.statusHistory.map((historyItem, index) => (
                <li
                  key={index}
                  style={{
                    marginBottom: "8px",
                    padding: "8px",
                    borderRadius: "4px",
                    backgroundColor:
                      historyItem.action === "Activated"
                        ? "rgba(0, 128, 0, 0.1)" // Light green for activated
                        : "rgba(255, 0, 0, 0.1)", // Light red for deactivated
                  }}
                >
                  <p style={{ margin: "4px 0" }}>
                    <strong>Action:</strong>{" "}
                    <span
                      style={{
                        color:
                          historyItem.action === "Activated" ? "green" : "red",
                      }}
                    >
                      {historyItem.action}
                    </span>
                  </p>
                  <p style={{ margin: "4px 0" }}>
                    <strong>Reason:</strong> {historyItem.reason || "No reason provided"}
                  </p>
                  <p style={{ margin: "4px 0" }}>
                    <strong>Date:</strong> {formatTimestamp(historyItem.timestamp)}
                  </p>
                </li>
              ))}
            </ul>
          </div>
        )}

        <textarea
          value={reason}
          onChange={(e) => setReason(e.target.value)}
          placeholder={`Enter your reason to ${
            clickOn === 0 ? "deactivate" : "activate"
          } user`}
          style={{
            width: "100%",
            padding: "8px",
            border: "1px solid #ccc",
            borderRadius: "4px",
            marginBottom: "16px",
            resize: "none",
            color: "#333",
          }}
        ></textarea>
        <div style={{ display: "flex", justifyContent: "flex-end", gap: "8px" }}>
          <button
            onClick={onClose}
            style={{
              padding: "8px 16px",
              backgroundColor: "#ccc",
              color: "white",
              border: "none",
              borderRadius: "4px",
              cursor: "pointer",
            }}
          >
            Cancel
          </button>
          <button
            onClick={handleSubmit}
            style={{
              padding: "8px 16px",
              backgroundColor: "#007BFF",
              color: "white",
              border: "none",
              borderRadius: "4px",
              cursor: "pointer",
            }}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default ReasonModal;



