import React, { useState, useEffect, useCallback } from "react";
import { toast } from "react-toastify";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import {
  getBlogs,
  deleteBlog,
  markBlogAsReviewed,
  markBlogAsPublished,
} from "../../apis";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import actions from "../../actions";
import Pagination from "../../components/Pagination/Pagination";
import AddEdit from "./AddEdit";
import { RxCrossCircled } from "react-icons/rx/index.esm";

const Blogs = ({ logoutRequest, startLoadingRequest, stopLoadingRequest }) => {
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [pageInfo, setPageInfo] = useState({})
  const { pathname, search } = useLocation();
const [searchParams] = useSearchParams();
const searchValue = searchParams.get('search') || '';
  
  const fetch = useCallback(async () => {
    try {
      const apiResponse = await getBlogs(search);
      if (apiResponse?.data?.data?.blogs?.length > 0) {
        setData({ ...apiResponse?.data?.data });
        setPageInfo({ ...apiResponse?.data?.data?.pageInfo})
      } else {
        setData({});
      }
      stopLoadingRequest();
    } catch (error) {
      if (error?.response?.status === 401) {
        toast.error(error.response.data.message);
        logoutRequest();
      } else if (error?.response?.status === 403) {
        toast.error(error.response.data.message);
      }
      stopLoadingRequest();
    }
  }, [data, search, pathname]);

  // useEffect(() => {
  //   startLoadingRequest();
  //   fetch();
  // }, [pathname]);
  const activateDeactivateBlogHandler = useCallback(async (id, isActive) => {
    try {
      startLoadingRequest();
      await deleteBlog(id);
      toast.success(
        isActive
          ? "Blog deactivated successfully"
          : "Blog activated successfully"
      );
      fetch();
    } catch (error) {
      if (error?.response?.status === 401) {
        toast.error(error.response.data.message);
        logoutRequest();
      } else if (error?.response?.status === 403) {
        toast.error(error.response.data.message);
      }
      stopLoadingRequest();
    }
  }, []);
  const markBlogAsReviewedHandler = useCallback(async (id) => {
    try {
      startLoadingRequest();
      await markBlogAsReviewed(id);
      toast.success("Blog is marked as reviewd");
      fetch();
    } catch (error) {
      toast.error(error.response.data.message);
      if (error?.response?.status === 401) {
        toast.error(error.response.data.message);
        logoutRequest();
      }
      stopLoadingRequest();
    }
  }, []);

  const markBlogAsPublishedHandler = useCallback(async (id) => {
    try {
      startLoadingRequest();
      await markBlogAsPublished(id);
      toast.success("Blog is published");
      fetch();
    } catch (error) {
      toast.error(error.response.data.message);
      if (error?.response?.status === 401) {
        toast.error(error.response.data.message);
        logoutRequest();
      }
      stopLoadingRequest();
    }
  }, []);

  const searchChangeHandler = useCallback((event) => {
    navigate(`/blogs?page=1&search=${encodeURIComponent(event.target.value)}`);
  }, []);

  const resetSearchHandler = useCallback(() => {
    navigate('/blogs?page=1&search=');
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      startLoadingRequest();
      fetch();
    }, 1000);
    return () => clearTimeout(timeout);
  }, [pathname, search]);


  return (
    <div className="section">
      <h1>Blogs</h1>
      <div className="wrapper">
        <div className="table-wrapper">
          <div className="table-header">
          <div className="search">
          <div className="input-wrapper">
      <input
        value={searchValue}
        onChange={searchChangeHandler}
        placeholder="Search..."
      />
      {searchValue && (
        <RxCrossCircled onClick={resetSearchHandler} size={18} />
      )}
    </div>

              </div>

            <AddEdit type="blog-add" fetch={fetch} />
          </div>
          <div className="table-container">
            <table>
              <thead>
                <th>#</th>
                <th>Image</th>
                <th>Headline</th>
                <th>Sub Heading</th>
                <th>Content</th>
                <th>Reading Time</th>
                <th>Category</th>
                <th>Active/Inactive</th>
                <th>Created at</th>
                <th>Actions</th>
              </thead>
              <tbody>
                {data?.blogs?.map((item, index) => (
                  <tr key={item.id}>
                    <td>{index + 1 + (pageInfo?.page - 1) * pageInfo?.size}</td>
                    <td>
                      {item?.document?.url && <img src={item.document.url} />}
                    </td>
                    <td>{item.headline}</td>
                    <td>{item.subHeading}</td>
                    <td>{item.content}</td>
                    <td>{item.readingTime}</td>
                    <td>{item.category?.name}</td>
                    <td>{item.isActive ? "Active" : "Inactive"}</td>
                    <td>{new Date(item.createdAt).toLocaleDateString()}</td>
                    <td>
                      <div className="table-actions">
                        {item?.isActive && (
                          <>
                            <AddEdit
                              type={`blog-${item.id}`}
                              data={item}
                              fetch={fetch}
                            />
                            {/* {item.isActive &&
                              item.status === "Under Review" && (
                                <button
                                  className="success"
                                  onClick={() =>
                                    markBlogAsReviewedHandler(item.id)
                                  }
                                >
                                  Mark as reviewed
                                </button>
                              )}
                            {item.isActive && item.status === "Reviewed" && (
                              <button
                                className="success"
                                onClick={() =>
                                  markBlogAsPublishedHandler(item.id)
                                }
                              >
                                Publish
                              </button>
                            )} */}
                          </>
                        )}
                        <button
                          className={item.isActive ? "danger" : "success"}
                          onClick={() =>
                            activateDeactivateBlogHandler(
                              item.id,
                              item.isActive
                            )
                          }
                        >
                          {item.isActive ? "Deactivate" : "Activate"}
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <Pagination pageInfo={data?.pageInfo} />
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logoutRequest: actions.auth.logout,
      stopLoadingRequest: actions.loader.stopLoading,
      startLoadingRequest: actions.loader.startLoading,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(Blogs);
