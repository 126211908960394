import React, { useState, useEffect, useCallback } from "react";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getProductCategories,
  activateDeactivateProductCategory,
} from "../../apis";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import actions from "../../actions";
import AddEdit from "./AddEdit";
import { RxCrossCircled } from "react-icons/rx/index.esm";
import Pagination from "../../components/Pagination/Pagination";

const ProductCategories = ({
  logoutRequest,
  startLoadingRequest,
  stopLoadingRequest,
}) => {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const [data, setData] = useState({});
  const [pageInfo, setPageInfo] = useState({})
  const fetch = useCallback(async () => {
    try {
      const apiResponse = await getProductCategories(search);
      if (apiResponse?.data?.data?.productCategories?.length > 0) {
        setData({ ...apiResponse?.data?.data });
        setPageInfo({ ...apiResponse?.data?.data?.pageInfo})
      } else {
        setData({});
      }
      stopLoadingRequest();
    } catch (error) {
      if (error?.response?.status === 401) {
        toast.error(error.response.data.message);
        logoutRequest();
      } else if (error?.response?.status === 403) {
        toast.error(error.response.data.message);
      }
      stopLoadingRequest();
    }
  }, [data, pathname,search]);

  // useEffect(() => {
  //   startLoadingRequest();
  //   fetch();
  // }, [pathname]);

  const activateDeactivateProductCategoryHandler = useCallback(
    async (id, requestType) => {
      try {
        startLoadingRequest();
       const response = await activateDeactivateProductCategory(id, {
          requestType: requestType ? "0" : "1",
        });
        toast.success(response.data.message);
        fetch();
      } catch (error) {
        if (error?.response?.status === 401) {
          toast.error(error.response.data.message);
          logoutRequest();
        } else if (error?.response?.status === 403) {
          toast.error(error.response.data.message);
        }
        stopLoadingRequest();
      }
    },
    []);

  const searchChangeHandler = useCallback((event) => {
    navigate(
      `/product-categories?page=1&search=${event.target.value}`
    );
  }, []);

  const resetSearchHandler = useCallback(() => {
    navigate(
      `/product-categories?page=1&search=`
    );
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      startLoadingRequest();
      fetch();
    }, 1000);
    return () => clearTimeout(timeout);
  }, [pathname, search]);

  console.log(data?.pageInfo)

  return (
    <div className="section">
      <h1>Product Categories</h1>
      <div className="wrapper">
        <div className="table-wrapper">
          <div className="table-header">
            <div className="search">
        <div className="input-wrapper">
                <input
                  value={
                    search
                      ?.split("?")[1]
                      ?.split("&")
                      ?.filter((el) => el.includes("search"))[0]
                      ?.split("=")[1]
                      ?.trim() || ""
                  }
                  onChange={searchChangeHandler}
                  placeholder="Search..."
                />
                {search
                  ?.split("?")[1]
                  ?.split("&")
                  ?.filter((el) => el.includes("search"))[0]
                  ?.split("=")[1]
                  ?.trim() && (
                  <RxCrossCircled onClick={resetSearchHandler} size={18} />
                )}
              </div>
              </div>
            <AddEdit type="blog-categories-add" fetch={fetch} />
          </div>

          <div className="table-container">
            <table>
              <thead>
                <th>#</th>
                <th>Image</th>
                <th>Name</th>
                <th>Active/Inactive</th>
                <th>Actions</th>
              </thead>
              <tbody>
                {data?.productCategories?.map((item, index) => (
                  <tr key={item.id}>
                    <td>{index + 1 + (pageInfo?.page - 1) * pageInfo?.size}</td>
                    <td>
                      {item?.document?.url && <img src={item.document.url} />}
                    </td>
                    <td>{item.name}</td>
                    <td>{item.isActive ? "Active" : "Inactive"}</td>
                    <td>
                      <div className="table-actions">
                        {item.isActive && (
                          <>
                            <AddEdit
                              type={`blog-categories-${item.id}`}
                              data={item}
                              fetch={fetch}
                            />
                          </>
                        )}
                        <button
                          className={item.isActive ? "danger" : "success"}
                          onClick={() =>
                            activateDeactivateProductCategoryHandler(
                              item.id,
                              item.isActive
                            )
                          }
                        >
                          {item.isActive ? "Deactivate" : "Activate"}
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <Pagination pageInfo={data?.pageInfo} />
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logoutRequest: actions.auth.logout,
      stopLoadingRequest: actions.loader.stopLoading,
      startLoadingRequest: actions.loader.startLoading,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(ProductCategories);
