import React, { useState } from "react";

const Transactions = ({ transactions,customerInfo }) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const openPopup = () => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  return (
    <div>
      {/* Button to view the transactionss list */}
      <button
        onClick={openPopup}
        style={{
          backgroundColor: "#EFC081",
          color: "white",
          padding: "10px 20px",
          fontSize: "1rem",
          borderRadius: "5px",
          cursor: "pointer",
          border: "none",
          fontWeight: "bold",
        }}
      >
        View transactions
      </button>

      {/* Popup Modal */}
      {isPopupOpen && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1000,
          }}
        >
          <div
            style={{
              width: "80%",
              maxHeight: "90vh",
              backgroundColor: "white",
              borderRadius: "10px",
              overflowY: "auto",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
              padding: "20px",
            }}
          >
            <div style={{ textAlign: "right" }}>
              <button
                onClick={closePopup}
                style={{
                  backgroundColor: "#ff4d4d",
                  color: "white",
                  border: "none",
                  padding: "10px 20px",
                  fontSize: "1rem",
                  cursor: "pointer",
                  borderRadius: "5px",
                  fontWeight: "bold",
                  transition: "background-color 0.3s",
                }}
                onMouseOver={(e) =>
                  (e.target.style.backgroundColor = "#cc0000")
                }
                onMouseOut={(e) =>
                  (e.target.style.backgroundColor = "#ff4d4d")
                }
              >
                Close
              </button>
            </div>

            <h2
              style={{
                textAlign: "center",
                marginBottom: "20px",
                color: "black",
                fontWeight: "bold",
                fontSize: "24px",
              }}
            >
              Order transactions
            </h2>
            <div style={{ fontWeight: "bold",color: "black" }}>
              <strong >Customer Name:</strong> {customerInfo?.firstName +" "+ customerInfo?.lastName || "N/A"}
            </div>
            <div style={{ fontWeight: "bold",color: "black" }}>
              <strong>Customer Email:</strong> {customerInfo?.email || "N/A"}
            </div>

            {transactions?.length > 0 ? (
  <ul style={{ padding: 0, margin: 0, listStyle: "none" }}>
   {transactions.map((transaction, index) => (
  <li
    key={index}
    style={{
      marginBottom: "20px",
      padding: "15px",
      border: "1px solid #ddd",
      borderRadius: "8px",
      background: index % 2 === 0 ? "#f9f9f9" : "#f1f1f1", // Alternating background colors
      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    }}
  >
    <h3
      style={{
        marginBottom: "10px",
        color: "black",
        fontWeight: "bold",
      }}
    >
      Transaction {index + 1}
    </h3>
    <hr style={{ margin: "10px 0", border: "1px solid #ddd" }} /> {/* Line separator */}

    {/* Display transaction details in lines */}
    <div style={{ color: "black" }}>
      <strong>Transaction ID:</strong> {transaction?.id || "N/A"}
    </div>
    <div style={{ color: "black" }}>
      <strong>Order ID:</strong> {transaction?.orderId || "N/A"}
    </div>
    <div style={{ color: "black" }}>
      <strong>Status:</strong> {transaction?.status || "N/A"}
    </div>
    <div style={{ color: "black" }}>
      <strong>Payment Method:</strong> {transaction?.method || "N/A"}
    </div>
    <div style={{ color: "black" }}>
      <strong>Payment Mode:</strong> {transaction?.mode || "N/A"}
    </div>
    <div style={{ color: "black" }}>
      <strong>Payment ID:</strong> {transaction?.paymentId || "N/A"}
    </div>
    <div style={{ color: "black" }}>
      <strong>Message:</strong> {transaction?.message || "N/A"}
    </div>
    <div style={{ color: "black" }}>
      <strong>Amount:</strong> {transaction?.amount || "N/A"}
    </div>
    <div style={{ color: "black" }}>
      <strong>Currency:</strong> {transaction?.currency || "N/A"}
    </div>

    {/* Date details */}
    <div style={{ marginTop: "10px", color: "black" }}>
      <strong>Created At:</strong> {transaction?.createdAtDate || "N/A"}
    </div>
    <div style={{ color: "black" }}>
      <strong>Processed At:</strong> {transaction?.processedAtDate || "N/A"}
    </div>

    {/* Add other transaction details as needed */}
  </li>
))}

  </ul>
) : (
  <p style={{ color: "black" }}>No transactionss available</p>
)}

          </div>
        </div>
      )}
    </div>
  );
};

export default Transactions;
