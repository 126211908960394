import React, { useCallback, useState, useEffect } from "react";
import { IoClose } from "react-icons/io5";
import { toast } from "react-toastify";
import SelectField from "../../components/Select/Select";
import { getPermissions, createAdmin, updateAdmin } from "../../apis";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import actions from "../../actions";
import isEqual from 'lodash.isequal';
const AddEdit = ({
    fetch,
    data,
    logoutRequest,
    startLoadingRequest,
    stopLoadingRequest,
    type
}) => {
    const [open, setOpen] = useState(false);

    const [formData, setFormData] = useState({
        docId: data?.document?.id || "",
        image: data?.document?.url || "",
        title: data?.title || "",
        message: data?.message || "",
        isBroadcast: data?.isBroadcast || false,
        isLive: data?.isLive || false,
        scheduledAt: data?.scheduledAt || "",
    });

    const [initialFormData, setInitialFormData] = useState({
        docId: data?.document?.id || "",
        image: data?.document?.url || "",
        title: data?.title || "",
        message: data?.message || "",
        isBroadcast: data?.isBroadcast || false,
        isLive: data?.isLive || false,
        scheduledAt: data?.scheduledAt || "",
    });

    const [errors, setErrors] = useState({
        docId: "",
        title: "",
        message: "",
        isBroadcast: '',
        isLive: '',
        scheduledAt: "",
    });

    const openHandler = useCallback(() => {
        setErrors({});
        setOpen(!open);
    }, [open]);

    const changeHandler = useCallback(
        (e) => {
            setFormData({
                ...formData,
                [e.target.name]: e.target.value,
            });
        },
        [formData]
    );

    useEffect(() => {
        if (open) {
            setFormData({
                docId: data?.document?.id || "",
                image: data?.document?.url || "",
                title: data?.title || "",
                message: data?.message || "",
                isBroadcast: data?.isBroadcast || false,
                isLive: data?.isLive || false,
                scheduledAt: data?.scheduledAt || "",
            });
            // fetchPermissions();
        }
    }, [open]);

    useEffect(() => {
        setInitialFormData({
            docId: data?.document?.id || "",
            image: data?.document?.url || "",
            title: data?.title || "",
            message: data?.message || "",
            isBroadcast: data?.isBroadcast || false,
            isLive: data?.isLive || false,
            scheduledAt: data?.scheduledAt || "",
        });
    }, [data]);

    const submitHandler = useCallback(async () => {
        try {
            startLoadingRequest();
            let cleanData = {
                title: formData?.title?.trim(),
                message: formData?.message?.trim(),
                isBroadcast: formData?.isBroadcast?.trim(),
            };
            let tempErrors = {};
            if (!cleanData.title) {
                tempErrors["title"] = "First name is required";
            }
            if (!cleanData.message) {
                tempErrors["message"] = "Last name is required";
            }

            // isBroadcast validation
            if (!cleanData.isBroadcast) {
                tempErrors["isBroadcast"] = "isBroadcast is required";
            }

            if (Object.keys(tempErrors)?.length > 0) {
                setErrors(tempErrors);
                stopLoadingRequest();
                return;
            } else {
                setErrors({});
            }
            if (data?.id) {
                const response = await updateAdmin(data?.id, cleanData);
                toast(response.data.message);
            } else {
                const response = await createAdmin(cleanData);
                toast(response.data.message);
            }
            setOpen(false);
            setFormData({
                title: "",
                message: "",
                isBroadcast: "",
                permissions: [{ value: "", label: "" }],
                gender: { value: "", label: "" },
                password: "",
            });
            setErrors({
                title: "",
                message: "",
                isBroadcast: "",
                permissions: "",
                gender: "",
                password: "",
            });
            fetch();
        } catch (error) {
            stopLoadingRequest();
            toast.error(error?.response?.data?.message);
            if (error?.response?.status === 401) {
                logoutRequest();
            }
        }
    }, [formData, data]);

    const isDisabled = data?.id ? isEqual(formData, initialFormData) : false;

    const fileHandler = useCallback(
        (event) => {
          if (event?.target?.files[0]) {
            const file = event.target.files[0];
            const img = new Image();
            const objectUrl = URL.createObjectURL(file);
    
            img.onload = () => {
              const width = img.width;
              const height = img.height;
              const isSmartMirror = formData.type.value === "SmartMirrorHome";
              const expectedWidth = isSmartMirror ? 1500 : 1500;
              const expectedHeight = isSmartMirror ? 2252 : 664;
    
              // const tolerance = 0.05; // Allow up to 5% deviation
    
              // Check if the aspect ratio is close to 3:2 or 2:3
              if (width === expectedWidth && height === expectedHeight) {
                // Valid resolution, set the image and file data
                setFormData({
                  ...formData,
                  file: file,
                  image: objectUrl,
                });
              } else {
                // Invalid resolution
                const expectedAspectRatio = (
                  expectedWidth / expectedHeight
                ).toFixed(2);
                toast.error(
                  `Image resolution must be ${expectedWidth}x${expectedHeight} (${expectedAspectRatio}:1 aspect ratio)`
                );
                URL.revokeObjectURL(objectUrl); // Clean up the object URL
              }
            };
    
            img.onerror = () => {
              toast.error("Failed to load image");
              URL.revokeObjectURL(objectUrl);
            };
    
            img.src = objectUrl;
          }
        },
        [formData]
      );



    return (
        <div className="modal-container">
            <div className="add-modal">
                <button
                    className={data?.id ? "secondary" : "add"}
                    onClick={openHandler}
                >
                    {data?.id ? "Edit" : "Add"}
                </button>
            </div>
            <div className={open ? "modal active" : "modal"}>
                <div className="wrapper">
                    <div className="header">
                        <h2>{data?.id ? "Edit Notification" : "New Notification"}</h2>
                        <button type="button" className="close" onClick={openHandler}>
                            <IoClose size={18} />
                        </button>
                    </div>
                    <div className="content">
                        <div className="form-section">
                            <div
                                className="input-wrapper-image"
                                style={{
                                    border: !formData.image ? "2px solid #ccc" : "none",
                                    width: "100%",
                                    height: "300px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    textAlign: "center",
                                    position: "relative", // Ensure proper alignment of child elements
                                }}
                            >
                                {formData?.image ? (
                                    <div className="image">
                                        <img
                                            src={formData.image}
                                            alt="Uploaded Preview"
                                            onClick={() => document.getElementById(type).click()} // Trigger file input
                                            style={{
                                                cursor: "pointer",
                                                width: "100%",
                                                height: "100%",
                                                objectFit: "cover",
                                            }} // Ensure the image fits within the defined size
                                        />
                                    </div>
                                ) : (
                                    <label
                                        htmlFor={type}
                                        className="upload-button"
                                        style={{
                                            cursor: formData?.type?.value ? "pointer" : "not-allowed",
                                            color: "#555",
                                            fontSize: "14px",
                                            textAlign: "center",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            flexDirection: "column", // Optional for multiple lines of text
                                            width: "100%",
                                            height: "100%",
                                        }}
                                    >
                                        Upload Image{" "}
                                        {formData.type?.value === "SmartMirrorHome"
                                            ? "(Image Size - 1500 * 2252)"
                                            : "(Image Size - 1500 * 664)"}
                                    </label>
                                )}
                                <input
                                    disabled={!formData?.type?.value}
                                    onChange={fileHandler}
                                    accept="image/*"
                                    id={type}
                                    type="file"
                                    style={{ display: "none" }} // Hide the input field
                                    key={formData.image || Date.now()}
                                />
                                {errors?.docId && (
                                    <p
                                        style={{ position: "absolute", bottom: "-20px" }}
                                        className="error-text"
                                    >
                                        {errors?.docId}
                                    </p>
                                )}
                            </div>
                            <div className="input-wrapper">
                                <label>Title</label>
                                <input
                                    name="title"
                                    value={formData.title}
                                    onChange={changeHandler}
                                />
                                {errors.title && <p>{errors.title}</p>}
                            </div>
                            <div className="input-wrapper">
                                <label>Message</label>
                                <input
                                    name="message"
                                    value={formData.message}
                                    onChange={changeHandler}
                                />
                                {errors.message && <p>{errors.message}</p>}
                            </div>
                            <div className="input-wrapper">
                                <label>isBroadcast</label>
                                <input
                                    name="isBroadcast"
                                    value={formData.isBroadcast}
                                    onChange={changeHandler}
                                />
                                {errors.isBroadcast && <p>{errors.isBroadcast}</p>}
                            </div>
                        </div>
                    </div>
                    <div className="footer">
                        <button
                            type="button"
                            onClick={submitHandler}
                            style={isDisabled ? { cursor: 'not-allowed' } : { cursor: 'pointer' }}
                            disabled={isDisabled}
                            className="primary"
                        >
                            Submit
                        </button>

                    </div>
                </div>
            </div>
        </div>
    );
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            logoutRequest: actions.auth.logout,
            startLoadingRequest: actions.loader.startLoading,
            stopLoadingRequest: actions.loader.stopLoading,
        },
        dispatch
    );

export default connect(null, mapDispatchToProps)(AddEdit);
