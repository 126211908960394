// utils/validators.js
export const validateExternalLink = (formData, cleanData) => {
  const errors = {};

  // Check if type is "Salons" and CTA is "RedirectToLink"
  if (formData?.cta?.value === "RedirectToLink") {
    if (!cleanData.externalLink || cleanData.externalLink.trim() === "" || cleanData.externalLink.length === 0) {
      errors["externalLink"] = "External Link is required";
    }
    // else if (!isValidURL(cleanData.externalLink)) {
    //   errors["externalLink"] = "Invalid URL format";
    // }
  }

  return errors;
};

// Helper function to validate URL format
export const isValidURL = (url) => {
  const urlRegex = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w.-]*)*\/?$/;
  return urlRegex.test(url);
};
