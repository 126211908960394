import React, { useEffect } from "react";
import {
  Routes as Switch,
  Route,
  Navigate,
  useNavigate,
} from "react-router-dom";
import { connect } from "react-redux";
import Sidebar from "../Sidebar/Sidebar";
import routes from "../Sidebar/routes";
import Navbar from "../Navbar/Navbar";

const Routes = ({ sidebar, isAuthenticated, admin, isLoading }) => {
  const navigate = useNavigate();
  useEffect(() => {
    if (!isLoading) {
      if (!isAuthenticated) {
        if(localStorage.getItem("authorization"))
          localStorage.removeItem("authorization");
        navigate("/login");
      }
    }
  }, [isLoading, isAuthenticated]);

// Find the first route link
const firstRoute = routes?.find(
  (route) =>
    route?.isAuthenticated &&
    route?.permission &&
    admin?.permissions?.indexOf(route.permission) !== -1
);

  return (
    <Switch>
      <Route
        exact
        path="/*"
        element={
          isAuthenticated ? (
            <Navigate to={firstRoute?.link || "/dashboard?requestType=Today"} />
          ) : (
            <Navigate to="/login" />
          )
        }
      />
      {routes
        .filter((route) => !route.isAuthenticated)
        .map((route, index) => (
          <Route
            key={`route-${index}`}
            exact
            path={route.path}
            element={route.component}
          />
        ))}

      {routes
        .filter((route) => route.isAuthenticated && !route.permission)
        .map((route, index) => (
          <Route
            key={`route-1${index}`}
            exact
            path={route.path}
            element={
              <div className={sidebar ? "container active" : "container"}>
                <Navbar />
                <Sidebar />
                <div className="body-wrapper">{route.component}</div>
              </div>
            }
          />
        ))}
{/* work on these routes*/}
      {routes
        .filter(
          (route) =>
            route.isAuthenticated &&
            route.permission &&
            admin?.permissions?.indexOf(route.permission) !== -1
        )
        .map((route, index) => (
          <Route
            key={`route-1${index}`}
            exact
            path={route.path}
            element={
              <div className={sidebar ? "container active" : "container"}>
                <Navbar />
                <Sidebar />
                <div className="body-wrapper">{route.component}</div>
              </div>
            }
          />
        ))}
    </Switch>
  );
};

const mapStateToProps = (state) => ({
  sidebar: state.sidebar,
  isAuthenticated: state.auth.isAuthenticated,
  admin: state.auth.data,
  isLoading: state.loader,
});

export default connect(mapStateToProps, null)(Routes);
