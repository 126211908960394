import React, { useCallback, useEffect, useState } from "react";
import {
  FaAngleLeft,
  FaAnglesLeft,
  FaAnglesRight,
  FaAngleRight,
} from "react-icons/fa6/index.esm";

const ReportPagination = ({ pageInfo, onPageChange }) => {
  const [pages, setPages] = useState([]);
  const [lastPage, setLastPage] = useState(0);

  useEffect(() => {
    if (pageInfo?.page) {
      pagesHandler();
    }
  }, [pageInfo]);

  const pagesHandler = useCallback(() => {
    const temp = [];
    const tempLastPage =
      pageInfo?.total % 20 > 0
        ? Math.floor(pageInfo?.total / 20) + 1
        : Math.floor(pageInfo?.total / 20);
    setLastPage(tempLastPage);

    if (tempLastPage <= 5) {
      for (let i = 1; i <= tempLastPage; i++) {
        temp.push(i);
      }
    } else if (tempLastPage - pageInfo.page <= 2) {
      for (let i = tempLastPage - 4; i <= tempLastPage; i++) {
        temp.push(i);
      }
    } else if (pageInfo.page - 1 <= 2) {
      for (let i = 1; i <= 5; i++) {
        temp.push(i);
      }
    } else {
      for (let i = pageInfo.page - 2; i <= pageInfo.page + 2; i++) {
        temp.push(i);
      }
    }
    setPages(temp);
  }, [pageInfo]);

  const handlePageChange = (page) => {
    if (page !== pageInfo?.page && onPageChange) {
      onPageChange(page);
    }
  };

  return (
    <div className="pagination">
      <button
        className={pageInfo?.page !== 1 ? "active" : ""}
        onClick={() => handlePageChange(1)}
      >
        <FaAnglesLeft size={8} />
      </button>
      <button
        className={pageInfo?.page !== 1 ? "active" : ""}
        onClick={() => handlePageChange(pageInfo.page - 1)}
      >
        <FaAngleLeft size={8} />
      </button>
      <div className="pages">
        {pages?.map((el) => (
          <button
            key={el}
            className={pageInfo?.page === el ? "number active" : "number"}
            onClick={() => handlePageChange(el)}
          >
            <span>{el}</span>
          </button>
        ))}
      </div>
      <button
        className={pageInfo?.page !== lastPage ? "active" : ""}
        onClick={() => handlePageChange(pageInfo.page + 1)}
      >
        <FaAngleRight size={8} />
      </button>
      <button
        className={pageInfo?.page !== lastPage ? "active" : ""}
        onClick={() => handlePageChange(lastPage)}
      >
        <FaAnglesRight size={8} />
      </button>
      <p>
  Showing{" "}
  {pageInfo?.total === 0
    ? 0
    : pageInfo?.page
    ? (pageInfo.page - 1) * 20 + 1
    : 0}{" "}
  to{" "}
  {pageInfo?.total === 0
    ? 0
    : pageInfo?.page && pageInfo.total
    ? Math.min(pageInfo.page * 20, pageInfo.total)
    : 0}{" "}
  of {pageInfo?.total ?? 0}
</p>

    </div>
  );
};

export default ReportPagination;
