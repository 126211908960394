import React, { useState, useEffect, useCallback } from "react";
import { toast } from "react-toastify";
import { RxCrossCircled } from "react-icons/rx/index.esm";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { deleteUser, getNonOnboardedUsers } from "../../apis";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import actions from "../../actions";
import Pagination from "../../components/Pagination/Pagination";

const NonOnboardedUsers = ({ logoutRequest, startLoadingRequest, stopLoadingRequest }) => {
    const navigate = useNavigate();
    const [data, setData] = useState({});
    const [isOpen, setIsOpen] = useState(false); // State to control modal visibility
    const [userToDelete, setUserToDelete] = useState(false);
    const { pathname, search } = useLocation();
    const [searchParams] = useSearchParams();
    const searchValue = searchParams.get("search") || "";
    const [pageInfo, setPageInfo] = useState({})

    const fetch = useCallback(async () => {
        try {
            const apiResponse = await getNonOnboardedUsers(search);
            console.log(apiResponse)
            if (apiResponse?.data?.data?.users?.length > 0) {
                setData({ ...apiResponse?.data?.data });
                setPageInfo({ ...apiResponse?.data?.data?.pageInfo })
            } else {
                setData({});
            }
            stopLoadingRequest();
        } catch (error) {
            if (error?.response?.status === 401) {
                toast.error(error.response.data.message);
                logoutRequest();
            } else if (error?.response?.status === 403) {
                toast.error(error.response.data.message);
            }
            stopLoadingRequest();
        }
    }, [data, pathname, search]);

    const deleteUserHandler = useCallback(
        async (user) => {
            try {
                startLoadingRequest();
                await deleteUser(user?.id); // Send the reason along with the requestType
                setUserToDelete(null);
                setIsOpen(false);
                // fetch();
                // Preserve the current page and refetch data
                const searchParams = new URLSearchParams(search);
                const currentPage = searchParams.get("page") || 1;
                fetch(`?page=${currentPage}&${searchParams.toString()}`);
                toast.success("User deleted successfully");
            } catch (error) {
                if (error?.response?.status === 401) {
                    toast.error(error.response.data.message);
                    logoutRequest();
                } else if (error?.response?.status === 403) {
                    toast.error(error.response.data.message);
                }
                stopLoadingRequest();
            }
        },
        []
    );

    const searchChangeHandler = useCallback((event) => {
        navigate(`/non-onboarded-users?page=1&search=${encodeURIComponent(event.target.value)}`);
    }, []);

    const resetSearchHandler = useCallback(() => {
        navigate("/non-onboarded-users?page=1&search=");
    }, []);

    useEffect(() => {
        const timeout = setTimeout(() => {
            startLoadingRequest();
            fetch();
        }, 1000);
        return () => clearTimeout(timeout);
    }, [pathname, search]);

    // Function to open the modal for deletion
    const openModalHandler = (user) => {
        setUserToDelete(user); // Store the user info to deactivate
        setIsOpen(true); // Open the modal
    };

    //   const handleSubmitReason = (reason, ClickedOn) => {
    //     if (userToDeactivate) {
    //       activateDeactivateUserHandler(userToDeactivate.id, ClickedOn, reason); // Pass the reason to the handler
    //     }
    //   };
    console.log(pageInfo)
    return (
        <div className="section">
            <h1>Non Onboarded Users</h1>
            <div className="wrapper">
                <div className="table-wrapper">
                    <div className="table-header">
                        <div className="search">
                            <div className="input-wrapper">
                                <input
                                    value={searchValue}
                                    onChange={searchChangeHandler}
                                    placeholder="Search..."
                                />
                                {searchValue && (
                                    <RxCrossCircled onClick={resetSearchHandler} size={18} />
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="table-container">
                        <table>
                            <thead>
                                <th>#</th>
                                <th>Onboarding date</th>
                                <th>Phone number</th>
                                <th>Email</th>
                                <th>Actions</th>
                            </thead>
                            <tbody>
                                {data?.users?.map((item, index) => (
                                    <tr key={item._id}>
                                        <td>{index + 1 + (pageInfo?.page - 1) * pageInfo?.size}</td>
                                        <td>{item.createdAt}</td>
                                        <td>{item.phoneNumber ?? "NA"}</td>
                                        <td>{item.email ?? "NA"}</td>
                                        <td>
                                            <div className="table-actions">
                                                <button
                                                    className="danger"
                                                    onClick={() => {
                                                        openModalHandler(item); // Open the modal
                                                    }}
                                                >
                                                    Delete
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    {data?.users?.length > 0 && <Pagination pageInfo={data.pageInfo} />}
                </div>
            </div>
            {isOpen && (
                <div className="delete-popup">
                <div className="popup-content">
                  <h2>Are you sure you want to delete this user?</h2>
                  <div className="popup-actions">
                    <button
                      className="success"
                      onClick={() => deleteUserHandler(userToDelete)}
                    >
                      Confirm
                    </button>
                    <button className="danger"
                      onClick={() => setIsOpen(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            )}
        </div>
    );
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            logoutRequest: actions.auth.logout,
            stopLoadingRequest: actions.loader.stopLoading,
            startLoadingRequest: actions.loader.startLoading,
        },
        dispatch
    );

export default connect(null, mapDispatchToProps)(NonOnboardedUsers);
