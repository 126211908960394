import React, { useCallback, useState, useEffect } from "react";
import { IoClose } from "react-icons/io5";
import { toast } from "react-toastify";
import SelectField from "../../components/Select/Select";
import {
  createBanner,
  getCollections,
  updateBanner,
  uploadSingleDocument,
} from "../../apis";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import actions from "../../actions";
import { validateExternalLink } from "../../utils/LinkValidator";



const AddEdit = ({
  fetch,
  data,
  logoutRequest,
  startLoadingRequest,
  stopLoadingRequest,
  type,
}) => {
  const [open, setOpen] = useState(false);
  const [collections, setCollections] = useState([]);
  const [formData, setFormData] = useState({
    docId: data?.document?.id || "",
    title: data?.title || "",
    // gender: {value:data?.gender || "", label: data?.gender || ""},
    screenName: {
      value: data?.screenName || "",
      label: data?.screenName || "",
    },
    externalLink: data?.externalLink || "",
    cta: { value: data?.cta || "", label: data?.cta || "" },
    collectionIds: Array.isArray(data?.collectionIds)
      ? data.collectionIds.map((id) => {
          const collection = collections.find((col) => col.id === id);
          return {
            value: id,
            label: collection?.title || id, // Fallback to `id` if no label found
          };
        })
      : [],
    caption: data?.caption || "",
    type: { value: data?.type || "", label: data?.type || "" },

    image: data?.document?.url || "",
    file: "",
  });

  const [errors, setErrors] = useState({
    docId: "",
    title: "",
    caption: "",
    type: "",
    externalLink: "",
    screenName: "",
    // gender: "",
    collectionIds: "",
    cta: "",
  });

  const openHandler = useCallback(() => {
    if (!data?.id) {
      setFormData({
        docId: "",
        title: "",
        caption: "",
        type: { value: "", label: "" },
        // gender: { value: "", label: "" },
        externalLink: "",
        screenName: { value: "", label: "" },
        collectionIds: [],
        cta: { value: "", label: "" },
        image: "",
        file: "",
      });
      setErrors({
        docId: "",
        title: "",
        caption: "",
        externalLink: "",
        screenName: "",
        collectionIds: "",
        type: "",
        cta: "",
      });

    }
    setOpen(!open);
  }, [open]);

  const changeHandler = useCallback(
    (e) => {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    },
    [formData]
  );
  const selectDropdownChangeHandler = useCallback(
    (name, value) => {
      setFormData({
        ...formData,
        [name]: value,
        ...(name === "type" && {
          docId: "",
          image: "",
          screenName: "",
          externalLink:"",
          cta: { value: "", label: "" }, // Reset CTA
          collectionIds: [], // Reset Collection IDs
        }),
        ...(name === "cta" && {
          screenName: "",
          externalLink:"",
        })
      });
    },
    [, data, formData]
  );
  const fileUploadHandler = useCallback(async () => {
    if (typeof formData?.file === "object") {
      const form = new FormData();
      form.append("file", formData.file);
      const apiResponse = await uploadSingleDocument(form);
      return apiResponse?.data?.data?.documentId;
    } else {
      return formData?.docId || "";
    }
  }, [formData]);

  const submitHandler = useCallback(async () => {
    try {
      startLoadingRequest();
      const docId = await fileUploadHandler();
      let cleanData = {
        // gender: formData?.gender?.value?.trim(),
        externalLink: formData?.externalLink?.trim() === "" ? null : formData?.externalLink?.trim(),
        screenName: formData?.screenName?.value?.trim() === "" ? null : formData?.screenName?.value?.trim(),
        cta: formData?.cta?.value?.trim(),
        collectionIds: formData?.collectionIds.map((id) => id.value),
        title: formData?.title?.trim(),
        caption: formData?.caption?.trim(),
        docId: docId || formData?.docId?.trim(),
        type: formData?.type?.value?.trim(),
      };
      // console.log("cleanData", cleanData);
      
      let tempErrors = {};
      if (!cleanData.type) {
        tempErrors["type"] = "Type is required";
      }
      if (!cleanData.docId) {
        tempErrors["docId"] = "Image is required";
      }
      if (!cleanData.caption) {
        tempErrors["caption"] = "Caption is required";
      }
      if (!cleanData.title) {
        tempErrors["title"] = "Title is required";
      }
      // if(cleanData?.type==="SmartMirrorHome"&&!cleanData.gender){
      //   tempErrors["gender"] = "Gender is required";
      // }
      if (!cleanData.cta) {
        tempErrors["cta"] = "CTA is required";
      }
      if (
        cleanData.cta === "RedirectToCollections" &&
        cleanData.collectionIds.length === 0
      ) {
        tempErrors["collectionIds"] = "Collection is required";
      }
      if (formData?.type?.value==="Salons"&& formData?.cta?.value==="RedirectToScreen"&&!cleanData.screenName) {
        tempErrors["screenName"] = "Screen Name is required";
      }
      // const errors = {}
      const linkErrors = validateExternalLink(formData, cleanData)
      console.log("linkError", linkErrors,linkErrors?.externalLink)
      if (linkErrors?.externalLink?.length > 0) {
        tempErrors["externalLink"] = linkErrors?.externalLink
        toast.error(linkErrors?.externalLink)
      }
      // console.log("cleanData", cleanData);
      // return
      
      // Object.assign(errors, linkErrors);
      if (Object.keys(tempErrors)?.length > 0) {
        setErrors(tempErrors);
        stopLoadingRequest();
        return;
      } else {
        setErrors({});
      }
      if (data?.id) {
        const response = await updateBanner(data?.id, cleanData);
        toast(response?.data?.message);
      } else {
        const response = await createBanner(cleanData);
        toast(response?.data?.message);
      }
      setOpen(false);
      setFormData({
        docId: data?.document?.id || "",
        title: data?.title || "",
        caption: data?.caption || "",
        externalLink: data?.externalLink || "",
        screenName: {
          value: data?.screenName || "",
          label: data?.screenName || "",
        },
        type: { value: data?.type || "", label: data?.type || "" },
        // gender: { value: data?.gender || "", label: data?.gender || "" },
        cta: { value: data?.cta || "", label: data?.cta || "" },
        collectionIds: Array.isArray(data?.collectionIds)
          ? data.collectionIds.map((id) => {
              const collection = collections.find((col) => col.id === id);
              return {
                value: id,
                label: collection?.title || id, // Fallback to `id` if no label found
              };
            })
          : [],
        image: data?.document?.url || "",
        file: "",
      });
      setErrors({
        docId: "",
        title: "",
        caption: "",
        collectionIds: "",
        externalLink: "",
        screenName: "",
        // gender: "",
        cta: "",
        type: "",
        image: "",
        file: "",
      });
      fetch();
    } catch (error) {
      stopLoadingRequest();
      toast.error(error?.response?.data?.message);
      if (error?.response?.status === 401) {
        logoutRequest();
      }
    }
  }, [formData, data]);

 

  useEffect(() => {
    if (data?.id) {
      setFormData({
        docId: data?.document?.id || "",
        title: data?.title || "",
        caption: data?.caption || "",
        type: { value: data?.type || "", label: data?.type || "" },
        externalLink: data?.externalLink || "",
        screenName: {
          value: data?.screenName || "",
          label: data?.screenName || "",
        },
        // gender: { value: data?.gender || "", label: data?.gender || "" },
        cta: { value: data?.cta || "", label: data?.cta || "" },
        collectionIds: Array.isArray(data?.collectionIds)
          ? data.collectionIds.map((id) => {
              const collection = collections.find((col) => col.id === id);
              return {
                value: id,
                label: collection?.title || id, // Fallback to `id` if no label found
              };
            })
          : [],
        image: data?.document?.url || "",
        file: "",
      });
    } else {
      setFormData({
        docId: "",
        title: "",
        caption: "",
        type: { value: "", label: "" },
        externalLink: "",
        screenName: { value: "", label: "" },
        // gender: { value: "", label: "" },
        collectionIds: [],
        cta: { value: "", label: "" },
        image: "",
        file: "",
      });
    }
  }, [data, open]);
  useEffect(() => {
    const fetchCollection = async () => {
      try {
        const res = await getCollections();
        setCollections(res?.data?.data?.collections);
      } catch (error) {
        console.log("error", error);
      }
    };

    if (
      formData?.cta?.value === "RedirectToCollections" &&
      formData?.type?.value === "Products"
    ) {
      fetchCollection();
    }
  }, [formData?.cta?.value]);

  // const fileHandler = useCallback(
  //   (event) => {
  //     if (event?.target?.files[0]) {
  //       setFormData({
  //         ...formData,
  //         file: event.target.files[0],
  //         image: URL.createObjectURL(event.target.files[0]),
  //       });
  //     }
  //   },
  //   [formData]
  // );
  const fileHandler = useCallback(
    (event) => {
      if (event?.target?.files[0]) {
        const file = event.target.files[0];
        const img = new Image();
        const objectUrl = URL.createObjectURL(file);

        img.onload = () => {
          const width = img.width;
          const height = img.height;
          const isSmartMirror = formData.type.value === "SmartMirrorHome";
          const expectedWidth = isSmartMirror ? 1500 : 1500;
          const expectedHeight = isSmartMirror ? 2252 : 664;

          // const tolerance = 0.05; // Allow up to 5% deviation

          // Check if the aspect ratio is close to 3:2 or 2:3
          if (width === expectedWidth && height === expectedHeight) {
            // Valid resolution, set the image and file data
            setFormData({
              ...formData,
              file: file,
              image: objectUrl,
            });
          } else {
            // Invalid resolution
            const expectedAspectRatio = (
              expectedWidth / expectedHeight
            ).toFixed(2);
            toast.error(
              `Image resolution must be ${expectedWidth}x${expectedHeight} (${expectedAspectRatio}:1 aspect ratio)`
            );
            URL.revokeObjectURL(objectUrl); // Clean up the object URL
          }
        };

        img.onerror = () => {
          toast.error("Failed to load image");
          URL.revokeObjectURL(objectUrl);
        };

        img.src = objectUrl;
      }
    },
    [formData]
  );

  // console.log("formData", !formData?.type?.value);
  return (
    <div className="modal-container">
      <div className="add-modal">
        <button
          className={data?.id ? "secondary" : "add"}
          onClick={openHandler}
        >
          {data?.id ? "Edit" : "Add"}
        </button>
      </div>
      <div className={open ? "modal active" : "modal"}>
        <div className="wrapper">
          <div className="header">
            <h2>{data?.id ? "Edit Banner" : "Add Banner"}</h2>
            <button type="button" className="close" onClick={openHandler}>
              <IoClose size={18} />
            </button>
          </div>
          <div className="content">
            <div className="form-section">
              <div className="input-wrapper">
                <label>Type</label>
                <SelectField
                  name="type"
                  onChange={selectDropdownChangeHandler}
                  value={formData.type}
                  isMulti={false}
                  options={[
                    {
                      // value: "Smart mirror(Home)",
                      value: "SmartMirrorHome",
                      label: "Smart mirror(Home)",
                    },
                    {
                      value: "Salons",
                      label: "Salons",
                    },
                    {
                      value: "Products",
                      label: "Products",
                    },
                    {
                      value: "SalonTwo",
                      label: "SalonTwo",
                    },
                    {
                      value: "LoyaltyRedeem",
                      label: "LoyaltyRedeem",
                    },
                    {
                      value: "LoyaltyEarn",
                      label: "LoyaltyEarn",
                    },
                    {
                      value: "Social",
                      label: "Social",
                    },
                    {
                      value: "Loyalty",
                      label: "Loyalty",
                    },
                  ]}
                />
                {errors.type && <p>{errors.type}</p>}
              </div>
              <div
                className="input-wrapper-image"
                style={{
                  border: !formData.image ? "2px solid #ccc" : "none",
                  width: "100%",
                  height: "300px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  position: "relative", // Ensure proper alignment of child elements
                }}
              >
                {formData?.image ? (
                  <div className="image">
                    <img
                      src={formData.image}
                      alt="Uploaded Preview"
                      onClick={() => document.getElementById(type).click()} // Trigger file input
                      style={{
                        cursor: "pointer",
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }} // Ensure the image fits within the defined size
                    />
                  </div>
                ) : (
                  <label
                    htmlFor={type}
                    className="upload-button"
                    style={{
                      cursor: formData?.type?.value ? "pointer" : "not-allowed",
                      color: "#555",
                      fontSize: "14px",
                      textAlign: "center",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column", // Optional for multiple lines of text
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    Upload Image{" "}
                    {formData.type?.value === "SmartMirrorHome"
                      ? "(Image Size - 1500 * 2252)"
                      : "(Image Size - 1500 * 664)"}
                  </label>
                )}
                <input
                  disabled={!formData?.type?.value}
                  onChange={fileHandler}
                  accept="image/*"
                  id={type}
                  type="file"
                  style={{ display: "none" }} // Hide the input field
                  key={formData.image || Date.now()}
                />
                {errors?.docId && (
                  <p
                    style={{ position: "absolute", bottom: "-20px" }}
                    className="error-text"
                  >
                    {errors?.docId}
                  </p>
                )}
              </div>

              {/* <div style={{overflowY:"scroll",display:"flex",flexDirection:"column",gap:"10px"}}> */}

              <div className="input-wrapper">
                <label>Title</label>
                <input
                  name="title"
                  value={formData.title}
                  onChange={changeHandler}
                />
                {errors.title && <p>{errors.title}</p>}
              </div>
              <div className="input-wrapper">
                <label>Caption</label>
                <input
                  name="caption"
                  value={formData.caption}
                  onChange={changeHandler}
                />
                {errors.caption && <p>{errors.caption}</p>}
              </div>

              {/* {formData?.type?.value==="SmartMirrorHome"&&<div className="input-wrapper">
                <label>Gender</label>
                <SelectField
                  name="gender"
                  onChange={selectDropdownChangeHandler}
                  value={formData.gender}
                  isMulti={false}
                  options={[
                    {
                      // value: "Smart mirror(Home)",
                      value: "Male",
                      label: "Male",
                    },
                    {
                      value: "Female",
                      label: "Female",
                    },
                    {
                      value: "NotSpecified",
                      label: "Not Specified",
                    },
                    // {
                    //   value: "Social",
                    //   label: "Social",
                    // },
                    {
                      value: "Other",
                      label: "Other",
                    },
                  ]}
                />
                {errors.type && <p>{errors.gender}</p>}
              </div>} */}
              <div className="input-wrapper">
                <label>CTA</label>
                <SelectField
                  name="cta"
                  onChange={selectDropdownChangeHandler}
                  value={formData.cta}
                  isMulti={false}
                  options={[
                    {
                      // value: "Smart mirror(Home)",
                      value: "Image",
                      label: "Image",
                    },
                    formData?.type?.value === "Products"
                      ? {
                          value: "RedirectToCollections",
                          label: "Redirect To Collection",
                        }
                      : null,
                    (formData?.type?.value === "Salons"|| formData?.type?.value==="SalonTwo"||formData?.type?.value==="LoyaltyRedeem"||formData?.type?.value==="LoyaltyEarn" || formData?.type?.value === "Social" || formData?.type?.value==="SmartMirrorHome")
                      ? {
                          value: "RedirectToScreen",
                          label: "Redirect To Screen",
                        }
                      : null,
                    (formData?.type?.value === "Salons" || formData?.type?.value==="SmartMirrorHome" || formData?.type?.value==="SalonTwo"||formData?.type?.value==="LoyaltyRedeem"||formData?.type?.value==="LoyaltyEarn")
                      ? {
                          value: "RedirectToLink",
                          label: "Redirect To Link",
                        }
                      : null,
                  ].filter(Boolean)}
                />
                {errors.type && <p>{errors.cta}</p>}
              </div>
              {formData?.cta?.value === "RedirectToCollections" &&
                formData?.type?.value === "Products" && (
                  <div className="input-wrapper">
                    <label>Collection IDs</label>
                    <SelectField
                      name="collectionIds"
                      onChange={selectDropdownChangeHandler}
                      value={formData.collectionIds}
                      isMulti={true}
                      options={collections?.map((item) => ({
                        value: item?.id,
                        label: item?.title,
                      }))}
                    />
                    {errors.type && <p>{errors.collectionIds}</p>}
                  </div>
                )}
              {formData?.cta?.value === "RedirectToScreen" &&
                 (
                  <div className="input-wrapper">
                    <label>Screens Name</label>
                    <SelectField
                      name="screenName"
                      onChange={selectDropdownChangeHandler}
                      value={formData.screenName}
                      // isMulti={true}
                      options={[
                        {
                          value: "SalonExploreAll",
                          label: "Salon explore all",
                        },
                      ]}
                    />
                    {errors.type && <p>{errors.screenName}</p>}
                  </div>
                )}
              {formData?.cta?.value === "RedirectToLink" && (
                  <div className="input-wrapper">
                    <label>External Link</label>
                    <input
                      name="externalLink"
                      value={formData.externalLink}
                      onChange={changeHandler}
                    />
                    {errors.type && <p>{errors.externalLink}</p>}
                  </div>
                )}
            </div>
          </div>
          <div className="footer">
            <button type="button" onClick={submitHandler} className="primary">
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logoutRequest: actions.auth.logout,
      startLoadingRequest: actions.loader.startLoading,
      stopLoadingRequest: actions.loader.stopLoading,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(AddEdit);
