import React, { useState, useEffect, useCallback } from "react";
import { RxCrossCircled } from "react-icons/rx/index.esm";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import { getAppointments } from "../../apis";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import actions from "../../actions";
import Pagination from "../../components/Pagination/Pagination";
import { Tooltip } from "react-tooltip";

const Appointments = ({
  logoutRequest,
  startLoadingRequest,
  stopLoadingRequest,
}) => {
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [pageInfo, setPageInfo] = useState({})
  const { pathname, search } = useLocation();
  const [searchParams] = useSearchParams();
  const searchValue = searchParams.get('search') || '';
  const fetch = useCallback(async () => {
    try {
      // setData({});
      const apiResponse = await getAppointments(search);
      if (apiResponse?.data?.data?.appointments?.length > 0) {
        setData({ ...apiResponse?.data?.data });
        setPageInfo({ ...apiResponse?.data?.data?.pageInfo})
      } else {
        setData({});
      }
      stopLoadingRequest();
    } catch (error) {
      if (error?.response?.status === 401) {
        toast.error(error.response.data.message);
        logoutRequest();
      } else if (error?.response?.status === 403) {
        toast.error(error.response.data.message);
      }
      stopLoadingRequest();
    }
  }, [data, search, pathname]);

  const requestTypeHandler = useCallback(
    (event) => {
      navigate(
        `/appointments?page=1&requestType=${event.target.value}&search=${
          search
            ?.split("?")[1]
            ?.split("&")
            ?.filter((el) => el.includes("search"))[0]
            ?.split("=")[1]
            ?.trim() || ""
        }`
      );
    },
    [search]
  );

  // const searchChangeHandler = useCallback((event) => {
  //   const currentRequestType =
  //     search
  //       ?.split("?")[1]
  //       ?.split("&")
  //       ?.filter((el) => el.includes("requestType"))[0]
  //       ?.split("=")[1]
  //       ?.trim() || "All";
  
  //   navigate(
  //     `/appointments?page=1&requestType=${currentRequestType}&search=${event.target.value}`
  //   );
  // }, [search]);

   const searchChangeHandler = useCallback((event) => {
      const currentRequestType = new URLSearchParams(search?.split("?")[1]).get("requestType") || "All";
      navigate(`/appointments?page=1&requestType=${currentRequestType}&search=${encodeURIComponent(event.target.value)}`);
    }, [search]);
  

  const resetSearchHandler = useCallback(() => {
    navigate(
      `/appointments?page=1&requestType=${
        search
          ?.split("?")[1]
          ?.split("&")
          ?.filter((el) => el.includes("requestType"))[0]
          ?.split("=")[1]
          ?.trim() || "All"
      }&search=`
    );
  }, [search]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      startLoadingRequest();
      fetch();
    }, 1000);
    return () => clearTimeout(timeout);
  }, [pathname, search]);

  useEffect(() => {
    startLoadingRequest();
  }, []);

  return (
    <div className="section">
      <h1>Appointments</h1>
      <div className="wrapper">
        <div className="table-wrapper">
          <div className="table-header flex-start">
            <div className="input-wrapper">
              <select
                value={
                  search
                    ?.split("?")[1]
                    ?.split("&")
                    ?.filter((el) => el.includes("requestType"))[0]
                    ?.split("=")[1] || "All"
                }
                onChange={requestTypeHandler}
              >
                <option value="Completed">Completed</option>
                <option value="Upcoming">Upcoming</option>
                <option value="Cancelled">Cancelled</option>
              </select>
            </div>
             <div className="search">
                      <div className="input-wrapper">
                  <input
                    value={searchValue}
                    onChange={searchChangeHandler}
                    placeholder="Search..."
                  />
                  {searchValue && (
                    <RxCrossCircled onClick={resetSearchHandler} size={18} />
                  )}
                </div>
                        </div>
            {/* <div className="search">
              <div className="input-wrapper">
                <input
                  value={
                    search
                      ?.split("?")[1]
                      ?.split("&")
                      ?.filter((el) => el.includes("search"))[0]
                      ?.split("=")[1]
                      ?.trim() || ""
                  }
                  onChange={searchChangeHandler}
                  placeholder="Search..."
                />
                {search
                  ?.split("?")[1]
                  ?.split("&")
                  ?.filter((el) => el.includes("search"))[0]
                  ?.split("=")[1]
                  ?.trim() && (
                  <RxCrossCircled onClick={resetSearchHandler} size={18} />
                )}
              </div>
            </div> */}
          </div>
          <div className="table-container">
            <table>
              <thead>
                <th>#</th>
                <th>Date</th>
                <th>Invoice Number</th>
                <th>Guest Name</th>
                <th>Phone Number</th>
                <th>Email</th>
                <th>Services</th>
                <th>Total amount</th>
              </thead>
              <tbody>
                {data?.appointments?.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1 + (pageInfo?.page - 1) * pageInfo?.size}</td>
                    <td>{item?.date}</td>
                    <td>{item?.invoiceNumber}</td>
                    <td>
                      {item?.guest?.firstName} {item?.guest?.lastName}
                    </td>
                    <td>{item?.guest?.phoneNumber}</td>
                    <td>{item?.guest?.email}</td>
                   
                    <td>{item?.services}</td>
                    <td>{item?.totalAmount}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {data?.appointments?.length > 0 && (
            <Pagination pageInfo={data.pageInfo} />
          )}
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logoutRequest: actions.auth.logout,
      stopLoadingRequest: actions.loader.stopLoading,
      startLoadingRequest: actions.loader.startLoading,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(Appointments);



// <div className="table-container">
// <table>
//   <thead>
//     <th>#</th>
//     <th>Date</th>
//     <th>Invoice Number</th>
//     <th>Guest Name</th>
//     <th>Phone Number</th>
//     <th>Email</th>
//     <th>Services</th>
//     <th>Total amount</th>
//   </thead>
//   <tbody>
//     {data?.appointments?.map((item, index) => (
//       <tr key={item.id}>
//         <td>{index + 1 + (pageInfo?.page - 1) * pageInfo?.size}</td>
//         <td>{item?.date}</td>
//         <td>{item?.invoiceNumber}</td>
//         <td>
//           {item?.guest?.firstName} {item?.guest?.lastName}
//         </td>
//         <td>{item?.guest?.phoneNumber}</td>
//         <td>{item?.guest?.email}</td>
//         <td>
//   <span
//     data-tooltip-id={`caption-tooltip-${item?.id}`}  // Make the id unique per item
//     style={{
//       cursor: "pointer",
//       display: "inline-block",
//       maxWidth: "200px", // Adjust as needed
//       wordWrap: "break-word", // Ensures text wraps
//       overflow: "hidden", // Ensures the text doesn't overflow outside
//       textOverflow: "ellipsis", // Adds ellipsis if text overflows
//     }}
//   >
//     {/* {item?.services?.join(", ")} */}
//     {item?.services}

//   </span>
//   <Tooltip
//     id={`caption-tooltip-${item?.id}`}  // Make the id unique per item
//     place="top"
//     type="dark"
//     effect="solid"
//     style={{
//       wordWrap: "break-word", // Ensures long words wrap properly
//       maxWidth: "300px", // Adjust the maximum width of the tooltip
//       whiteSpace: "normal", // Allows the text to break and wrap
//     }}
//   >
//     {/* {item?.services?.join(", ") || "No services"} */}
//     {item?.services}

//   </Tooltip>
// </td>
//         {/* <td>{item?.services?.join(", ")}</td> */}
//         <td>{item?.totalAmount}</td>
//       </tr>
//     ))}
//   </tbody>
// </table>
// </div>