import React, { useEffect, useState, useCallback } from "react";
import { RiDeleteBinFill } from "react-icons/ri/index.esm";
import { getSalons, uploadSingleDocument, updateSalon } from "../../apis";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import actions from "../../actions";
import { toast } from "react-toastify";
import "./index.css";

const Images = ({ logoutRequest, startLoadingRequest, stopLoadingRequest }) => {
  const [data, setData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [pageInfo, setPageInfo] = useState({})
  const fetch = useCallback(async () => {
    try {
      const apiResponse = await getSalons();
      if (apiResponse?.data?.data?.length > 0) {
        setData([...apiResponse?.data?.data]);
        setPageInfo({ ...apiResponse?.data?.data?.pageInfo})
      } else {
        setData([]);
      }
      stopLoadingRequest();
    } catch (error) {
      if (error?.response?.status === 401) {
        toast.error(error.response.data.message);
        logoutRequest();
      } else if (error?.response?.status === 403) {
        toast.error(error.response.data.message);
      }
      stopLoadingRequest();
    }
  }, []);

  useEffect(() => {
    startLoadingRequest();
    fetch();
  }, []);

  const searchChangeHandler = useCallback((event) => {
    setSearchValue(event.target.value);
  }, []);

  const clickHandler = useCallback((id) => {
    document.getElementById(id).click();
  }, []);

  const fileUploadHandler = useCallback(
    async (id, file) => {
      try {
        if (file) {
          const form = new FormData();
          form.append("file", file);
          const apiResponse = await uploadSingleDocument(form);
          let docId = apiResponse?.data?.data?.documentId;
          let images =
            data
              ?.filter((el) => el._id === id)[0]
              ?.images?.map((el) => el._id) || [];
          images.push(docId);
          await updateSalon(id, { images });
          toast.success("Salon image updated successfully");
          fetch();
        }
      } catch (error) {
        toast.error(error.response.data.message);
        if (error?.response?.status === 401) {
          logoutRequest();
        }
      }
    },
    [data]
  );

  const deleteImageHandler = useCallback(
    async (id, index) => {
      try {
        let images = data
          ?.filter((el) => el._id === id)[0]
          ?.images?.map((el) => el._id);
        images.splice(index, 1);
        console.log(index);
        await updateSalon(id, { images });
        toast.success("Salon image deleted successfully");
        fetch();
      } catch (error) {
        toast.error(error.response.data.message);
        if (error?.response?.status === 401) {
          logoutRequest();
        }
      }
    },
    [data]
  );

  return (
    <div className="section">
      <div className="wrapper">
        <div className="table-wrapper">
          <div className="table-header">
            <div className="search">
              <div className="input-wrapper">
                <input
                  value={searchValue}
                  onChange={searchChangeHandler}
                  placeholder="Search..."
                />
              </div>
            </div>
          </div>
          <div className="table-container salons">
            <table>
              <thead>
                <th>#</th>
                <th>Name</th>
                <th>Images</th>
                <th>Actions</th>
              </thead>
              <tbody>
                {data
                  ?.filter(
                    (item) =>
                      searchValue.trim() === "" ||
                      item.displayName
                        .toLowerCase()
                        .includes(searchValue.toLocaleLowerCase())
                  )
                  .map((item, index) => (
                    <tr key={item._id}>
                      <td>{index + 1 + (pageInfo?.page - 1) * pageInfo?.size}</td>
                      <td>{item.displayName}</td>
                      <td className="salon-images">
                        {item.images?.length > 0 &&
                          item.images.map((el, idx) => (
                            <div className="image">
                              <img key={el._id} src={el.url} />
                              <button
                                className="danger"
                                onClick={() =>
                                  deleteImageHandler(item._id, idx)
                                }
                              >
                                <RiDeleteBinFill size={16} />
                              </button>
                            </div>
                          ))}
                      </td>

                      <td>
                        <div className="input-wrapper-image">
                          <button
                            onClick={() =>
                              clickHandler(`salon-image-${item._id}`)
                            }
                            className="add"
                          >
                            Upload Image (Aspect ratio - 3:2)
                          </button>
                          <input
                            onChange={(event) =>
                              fileUploadHandler(
                                item._id,
                                event?.target?.files[0]
                              )
                            }
                            accept="Image/*"
                            id={`salon-image-${item._id}`}
                            type="file"
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logoutRequest: actions.auth.logout,
      stopLoadingRequest: actions.loader.stopLoading,
      startLoadingRequest: actions.loader.startLoading,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(Images);
